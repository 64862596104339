<template>
    <div>
        <v-icon color="primary" @click="setFilterShow">mdi-filter-plus</v-icon>
        <FilterList
            v-for="(group, index) in groups"
            :key="index"
            :items="group.items"
            :groupName="group.mame"
            :title="group.title"
            v-model="group.model"
        />

    </div>
</template>

<script>

import FilterList from '@/components/SettingMenuList.vue';

export default {
    name: 'SettingMenu',
    components: {
        FilterList,
    },
    data() {
        return {
            groups:[
                {
                    mame: 'group1',
                    model: true,
                    title: 'system_filters',
                    items: [
                        // {
                        //     title: 'menu_about',
                        //     route: {name: "about"},
                        //     visible: this.can(['administrator']),
                        // },
                        {
                            title: this.$t('menu_currencies'),
                            route: {name: "currency"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_pages'),
                            route: {name: "page"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_countries'),
                            route: {name: "country"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_statuses'),
                            route: {name: "status"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_sub_statuses'),
                            route: {name: "sub_status"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_loading_types'),
                            route: {name: "loading_type"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('type_clients'),
                            route: {name: "type_client"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('bases'),
                            route: {name: "base"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_type_transports'),
                            route: {name: "type_transport"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_type_containers'),
                            route: {name: "type_container"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_form_properties'),
                            route: {name: "form_property"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_invoice_statuses'),
                            route: {name: "invoice_status"},
                            visible: this.can(['administrator','director']),
                        },

                        {
                            title: this.$t('menu_task_statuses'),
                            route: {name: "task_status"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_funnel_statuses'),
                            route: {name: "funnel_status"},
                            visible: this.can(['administrator','director']),
                        },

                        {
                            title: this.$t('menu_type_vacation'),
                            route: {name: "type_vacation"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_type_activity'),
                            route: {name: "type_activity"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_attraction_channel'),
                            route: {name: "attraction_channel"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_task_type'),
                            route: {name: "task_type"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_case_type'),
                            route: {name: "case_type"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_location_event'),
                            route: {name: "location_event"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_case_status'),
                            route: {name: "case_status"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_suppliers'),
                            route: {name: "supplier"},
                            visible: this.can(['administrator','director']),
                        },




                        {
                            title: this.$t('menu_sexes'),
                            route: {name: "sex"},
                            visible: this.can(['administrator','director']),
                        },

                        {
                            title: this.$t('menu_type_expense'),
                            route: {name: "type_expense"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_deal_types'),
                            route: {name: "deal_type"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_type_export'),
                            route: {name: "type_export"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_payers'),
                            route: {name: "payer"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_type_form'),
                            route: {name: "type_form"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_tab'),
                            route: {name: "tab"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_required_field'),
                            route: {name: "required_field"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_eav_entities'),
                            route: {name: "eav.entity_type"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('task_score'),
                            route: {name: "task_score"},
                            visible: this.can(['administrator','director']),
                        },

                    ],
                },
                {
                    mame: 'group2',
                    model: true,
                    title: 'business_process',
                    items:[
                        {
                            title: this.$t('menu_business_process'),
                            route: {name: "business_process"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_deal_statuses'),
                            route: {name: "deal_status"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_deal_step'),
                            route: {name: "deal_step"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_document_type'),
                            route: {name: "document_type"},
                            visible: this.can(['administrator','director']),
                        },

                    ]
                },
                {
                    mame: 'group3',
                    model: true,
                    title: 'document_flow',
                    items:[
                        {
                            title: this.$t('menu_document_flow_statuses'),
                            route: {name: "document_flow_status"},
                            visible: this.can(['administrator','director']),
                        },
                        {
                            title: this.$t('menu_document_flow_types'),
                            route: {name: "document_flow_type"},
                            visible: this.can(['administrator','director']),
                        },
                    ]
                },
                {
                    mame: 'group4',
                    model: true,
                    title: 'create_report',
                    items:[
                        {
                            title: this.$t('menu_create_report'),
                            route: {name: "create_report"},
                            visible: this.can(['administrator','director']),
                        },
                    ]
                }
            ],
            filter_show: false,
        }
    },
    methods: {
        setFilterShow() {
            this.filter_show===true ? this.filter_show=false : this.filter_show=true
            this.$emit("filter_show", this.filter_show);
        },
    }
};
</script>
